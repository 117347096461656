// Do not touch: used by webpack to bundle CSS
import styles from './../css/main.css';

// Libraries
import './utils/modernizr-custom-3.5.0'
import $ from 'jquery'
//import Headroom from 'headroom.js'

// Only if one needs to support particular touch interactions
//import TouchEmulator from 'hammer-touchemulator'
//import Hammer from 'hammerjs'

// Detects
import MQ from './utils/mq'

// Polyfills
// Note: arrow functions, const, let, template literals, classes and special characters
// are transformed by babel at compile time
import 'core-js/es6/promise' // instead of import 'babel-polyfill' -> https://stackoverflow.com/a/36350399
import 'core-js/es6/array' // for Array.from
import objectFitImages from 'object-fit-images'

// Other utils
import GDPRElement from './modules/gdpr/gdpr'
import './modules/gdpr/ga'

GDPRElement('.gdpr-ga-state');
GDPRElement('.gdpr-ga-alert');

const mq = MQ([320, 480, 586, 640, 768, 840, 960, 1024, 1280, 1400]); // available breakpoints

checkIfOver320();
$(window).on('resize', checkIfOver320);

function checkIfOver320() {
  if(mq.over320) { // Configure viewports
    console.log('viewport over 320px');
  }
}

objectFitImages();

// ====================================
// Current page link
// ====================================
getCurrentLink();
changeLinkColor();
getCurrentFilter();
changeFilterColor();

// ====================
// Main Menu
function getCurrentLink() {
  var link = $(".header ul li a");
  link.each(function() {
    if (this.href == window.location.href) {
      $(this).addClass("current-page");
    }
  });
}

// Mouseover
function changeLinkColor() {
  var link = $(".header ul li a");
  var selectedLink = $(".header ul li a.current-page");

  link.on("mouseover", function() {
    link.not(this).css('opacity', '.4');
    $(this).css('opacity', '1');
  });

  link.on("mouseleave", function() {
    link.not(selectedLink).css('opacity', '.4');
    $(selectedLink).css('opacity', '1');
  });
}

// ====================
// Filters Menu
function getCurrentFilter() {
  var link = $(".menu-filter ul li a");
  link.each(function() {
    if (this.href == window.location.href) {
      $(this).addClass("current-page");
    }
  });
}
// Mouseover/Click
function changeFilterColor() {
  var filter = $(".menu-filter ul li a");
  var selectedFilter = $(".menu-filter ul li a.current-page");

  filter.on("click", function() {
    $(this).addClass("current-page");
    filter.not(selectedFilter).removeClass("current-page");
  });

  filter.on("mouseover", function() {
    $(this).css('opacity', '1');
    filter.not(this).css('opacity', '.4');
  });

  filter.on("mouseleave", function() {
    filter.not(selectedFilter).css('opacity', '.4');
    selectedFilter.css('opacity', '1');
  });
}


// ====================================
// Slick
// ====================================
import './modules/slick-slider/slick.js'
$(".gallery-video #slider-center").slick({
  vertical: true,
  arrows: true,
	infinite: false,
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplay: false,
	autoplaySpeed: 2000,
  verticalSwiping: true,
  responsive: [
    {
      breakpoint: 768,
      settings: "unslick"
    }
  ]
});

$("#slider-center-gallery").slick({
  arrows: true,
	infinite: false,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        dots: true
      }
    }
  ]
});

// ====================================
// Display sections only when loaded
// ====================================
$(window).on('load', function(){
  var targetCaption = $('main.homepage figcaption.prev-caption');
  // var targetArtist = $('div.wrapper-artist-content');
  var targetProject = $('section.project');
  var targetBio = $('div.bio-content');

  var loader = $('.loader');

  targetCaption.css('opacity', '1');
  // targetArtist.css('filter', 'blur(0)');
  targetProject.css('opacity', '1');
  targetBio.css('opacity', '1');

  // Hide loader
  loader.css('display', 'none');
});


// ====================================
// Video replace big one
// ====================================
// =====================
// Onready
setBigVideo();

function setBigVideo(){
  var firstEl = $('video.video-prev').first();
  var elSrc = firstEl.children('source');
  var firstSrc = elSrc.attr("src");
  var firstCap = firstEl.data('caption');

  var videoBig = $('video.video-big');
  var videoBigCap = $('figcaption.caption-big');

  console.log(firstSrc);
  console.log(firstCap);

  firstEl.css('opacity', '1');
  videoBig.attr('src', firstSrc);
  videoBigCap.text(firstCap);
}

// =====================
// Onclick
var videoPrev = $('video.video-prev');

videoPrev.on('click', function videoReplace() {
  var el = $(this);
  var elChild = el.children('source');
  var elChildSrc = elChild.attr("src");
  var elCap = el.data('caption');

  var videoBig = $('video.video-big');
  var videoBigCap = $('figcaption.caption-big');

  videoBig.attr('src', elChildSrc);
  videoBigCap.text(elCap);
});


// ====================================
// Video captions mobile
// ====================================
// =====================
// Onready
showCaptions();

// =====================
// Onresize
$(window).resize(function(){
  showCaptions();
})

function showCaptions() {
  var cap = $('.caption-mobile');
  var capSlickSlide = cap.closest(".slick-slide");
  if ($(window).width() < 768) {
     capSlickSlide.css('display', 'block');
  }
  else {
     capSlickSlide.css('display', 'none');
  }
}

// ====================================
// Logo typwriting effect
// ====================================
const words = ["trbl mngt", "trouble management"];
let i = 0;
let timer;

function typingEffect() {
	let word = words[i].split("");
	var loopTyping = function() {
		if (word.length > 0) {
			document.getElementById('word').innerHTML += word.shift();
		} else {
			// deletingEffect();
      setTimeout(deletingEffect, 3000);
			return false;
		};
		timer = setTimeout(loopTyping, 180);
	};
	loopTyping();
};

function deletingEffect() {
	let word = words[i].split("");
	var loopDeleting = function() {
		if (word.length > 0) {
			word.pop();
			document.getElementById('word').innerHTML = word.join("");
		} else {
			if (words.length > (i + 1)) {
				i++;
			} else {
				i = 0;
			};
			typingEffect();
			return false;
		};
		timer = setTimeout(loopDeleting, 80);
	};
	loopDeleting();
};

typingEffect();


// ====================================
// Images lazy
// ====================================
removeLazyFirst();

function removeLazyFirst() {
  var elParent_first = $(".lazyParent:first");
  var elParent_second = $("div.row:nth-of-type(2) .lazyParent");

  var elChild_first_first = $(".lazyParent:first img:nth-of-type(1)");
  var elChild_first_second = $(".lazyParent:first img:nth-of-type(2)");
  var elChild_second_first = $("div.row:nth-of-type(2) .lazyParent img:nth-of-type(1)");
  var elChild_second_second = $("div.row:nth-of-type(2) .lazyParent img:nth-of-type(2)");

  elParent_first.removeClass("lazyParent lazyAnimation safe-height");
  elParent_second.removeClass("lazyParent lazyAnimation safe-height");

  elChild_first_first.removeClass("lazy").addClass("prova");
  elChild_first_second.removeClass("lazy").addClass("prova");
  elChild_second_first.removeClass("lazy").addClass("prova");
  elChild_second_second.removeClass("lazy").addClass("prova");


  elChild_first_first.attr({
      src: elChild_first_first.attr('data-src')
  }).removeAttr('data-src');

  elChild_first_second.attr({
      src: elChild_first_second.attr('data-src')
  }).removeAttr('data-src');

  elChild_second_first.attr({
      src: elChild_second_first.attr('data-src')
  }).removeAttr('data-src');

  elChild_second_second.attr({
      src: elChild_second_second.attr('data-src')
  }).removeAttr('data-src');
}

document.addEventListener("DOMContentLoaded", function() {
  var lazyloadImages;

  if ("IntersectionObserver" in window) {
    console.log('Intersection observer available')
    lazyloadImages = document.querySelectorAll(".lazy");

    var imageObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.src = image.dataset.src;
          image.classList.remove("lazy");

          /* Animate the parent */
          var parentFig = image.parentElement;
          parentFig.classList.remove("lazyParent");
          parentFig.classList.remove("lazyAnimation");

          setTimeout(function() {
            parentFig.classList.remove("safe-height");
          }, 1000 );

          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function(image) {
      imageObserver.observe(image);
    });

    } else {
      console.log('No intersection observer available');
      var lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll(".lazy");

      function lazyload () {
        if(lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function() {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function(img) {
              if(img.offsetTop < (window.innerHeight + scrollTop)) {
                img.src = img.dataset.src;
                img.classList.remove('lazy');
                /* Load parent */
                var parentFig = img.parentElement;
                parentFig.classList.remove("lazyParent");
                parentFig.classList.remove("lazyAnimation");
                parentFig.classList.remove("safe-height");
              }
          });
          if(lazyloadImages.length == 0) {
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
        }, 20);
      }

    document.addEventListener("scroll", lazyload);
    window.addEventListener("resize", lazyload);
    window.addEventListener("orientationChange", lazyload);
  }
})


// ====================================
// Artists category hover preview
// ====================================
function showPreview() {
  var el = $('li.menu-item-object-roles a');

  el.on("mouseover", function() {
    var curEl = $(this);
    var curElStr = curEl.text().replace(' ','');
    var curArtists = $('.'+curElStr);
    var artists = $('a.artist');
    artists.not(curArtists).addClass('temp-not-active');
    curArtists.addClass('temp-active');
  })

  el.on("mouseleave", function() {
    var curEl = $(this);
    var curElStr = curEl.text().replace(' ','');
    var curArtists = $('.'+curElStr);
    var artists = $('a.artist');
    artists.not(curArtists).removeClass('temp-not-active');
    curArtists.removeClass('temp-active');
  })
}


jQuery(document).ready(function($) {
    showPreview();
});



// ====================================
// Show/hide artist page content
// ====================================
function init() {
    var controllers = $("[data-blink=controller]");
    var currHash = window.location.hash;
    for (var i = 0; i < controllers.length; i++) {
        var controller = $(controllers[i]);
        var id = controller.attr("data-blink-controls");
        var idStr = id.replace(' ','');;
        var initialState = controller.attr("data-blink-init");

        var container = $("[data-blink-id=" + idStr + "]");
        if (initialState === "hidden") {
            container.hide();
        } else if (initialState === "shown") {
            container.show();
        }

        // First controller initial state (shown)
        var controllerFirst = $(controllers).first();
        var initialStateFirst = controllerFirst.attr("data-blink-init", "shown");
        var idFirst = controllerFirst.attr("data-blink-controls");
        var containerFirst = $("[data-blink-id=" + idFirst + "]");
        containerFirst.show();
        controllerFirst.addClass("current-page");
    }

    controllers.on("click", function() {
        var controller = $(this);

        var id = controller.attr("data-blink-controls");
        var idStr = id.replace(' ','');;

        var state = controller.attr("data-blink-state");
        var initialState = controller.attr("data-blink-init");

        if (state === undefined) {
            state = initialState;
        }

        var newState = state === "hidden" ? "shown" : "hidden";

        var container = $("[data-blink-id=" + idStr + "]");

        var containerAll = $("[data-blink-id]");
        var controllerAll = $("[data-blink=controller]");

        if (newState === "shown") {
            controller.addClass("current-page");
            controller.attr("data-blink-init", "shown");

            controllers.not(controller).removeClass("current-page");

            container.show();
            containerAll.not(container).hide();

            controllerAll.not(controller).attr("data-blink-state", "hidden");
            controllerAll.not(controller).attr("data-blink-init", "hidden");
        }

        controller.attr("data-blink-state", newState);
    });

    controllers.on("mouseover", function() {
      $(this).css('opacity', '1');
      controllers.not(this).css('opacity', '.4');
    });

    controllers.on("mouseleave", function() {
      var selectedFilter = $(".menu-filter ul li a.current-page");
      controllers.not(selectedFilter).css('opacity', '.4');
      selectedFilter.css('opacity', '1');
    });
}

jQuery(document).ready(function($) {
    init();
});



// ====================================
// Check hash artist page content
// ====================================
function checkHash() {
    var currHash = window.location.hash;
    var currId = currHash.replace('#','');

    console.log('currHash='+currHash);
    var controllers = $("[data-blink=controller]");
    var containers = $("[data-blink=container]");

    var container = $("[data-blink-id=" + currId + "]");
    var controller = $("[data-blink-controls=" + currId + "]");


    // Give current hash to body as ID
    $('body').attr('id', currId);

    container.show();
    containers.not(container).hide();

    // active
    controller.addClass('current-page');
    controller.attr("data-blink-state", "shown");
    controller.attr("data-blink-init", "shown");

    // not active
    controllers.not(controller).css('opacity', '.4');
    controllers.not(controller).removeClass("current-page");
    controllers.not(controller).attr("data-blink-state", "hidden");
    controllers.not(controller).attr("data-blink-init", "hidden");
}

jQuery(document).ready(function($) {
    checkHash();
});


// ====================================
// Artist subcontent anchor top
// ====================================
$("main.single-artist div.menu-filter ul li a").click(function() {
  $("html, body").scrollTop( 0 );
  return false;
});


// ====================================
// Video player
// ====================================
// function setVideoPlayer() {
//   // const btnBackward = document.querySelector('.btn-backward');
//   // const btnExpand = document.querySelector('.btn-expand');
//   // const btnMute = document.querySelector('.btn-mute');
//   // const btnMuteIcon = btnMute.querySelector('.fa');
//   const btnPlay = document.querySelector('.btn-play');
//   const btnPlayIcon = btnPlay.querySelector('.fa');
//   // const btnForward = document.querySelector('.btn-forward');
//   const btnReset = document.querySelector('.btn-reset');
//   // const btnStop = document.querySelector('.btn-stop');
//   // const progressBar = document.querySelector('.progress-bar');
//   // const progressBarFill = document.querySelector('.progress-bar-fill');
//   const videoElement = document.querySelector('.video-element');
//
//   // Toggle full-screen mode
//   // const expandVideo = () => {
//   //   if (videoElement.requestFullscreen) {
//   //     videoElement.requestFullscreen();
//   //   } else if (videoElement.mozRequestFullScreen) {
//   //     // Version for Firefox
//   //     videoElement.mozRequestFullScreen();
//   //   } else if (videoElement.webkitRequestFullscreen) {
//   //     // Version for Chrome and Safari
//   //     videoElement.webkitRequestFullscreen();
//   //   }
//   // }
//
//   // Play / Pause the video
//   const playPauseVideo = () => {
//     if (videoElement.paused) {
//       videoElement.play();
//       $(".btn-play span img").attr("src","https://trbl.parcodiyellowstone.it/wp-content/uploads/2019/04/trbl-pause.svg");
//       btnPlayIcon.classList.remove('fa-play');
//       btnPlayIcon.classList.add('fa-pause');
//     } else {
//       videoElement.pause();
//       $(".btn-play span img").attr("src","https://trbl.parcodiyellowstone.it/wp-content/uploads/2019/04/trbl-play.svg");
//       btnPlayIcon.classList.remove('fa-pause');
//       btnPlayIcon.classList.add('fa-play');
//     }
//   }

  // Event listeners
//   // btnExpand.addEventListener('click', expandVideo, false);
//   btnPlay.addEventListener('click', playPauseVideo, false);
//   videoElement.addEventListener('ended', () => {
//     btnPlay.setAttribute('hidden', 'true');
//     btnReset.removeAttribute('hidden');
//   }, false);
// }

// jQuery(document).ready(function($) {
//     setVideoPlayer();
// });


// ====================================
// Slick arrow hover state
// ====================================
slickArrowHover();

function slickArrowHover() {
  var targetPrev = $('section.project .slick-prev');
  var targetNext = $('section.project .slick-next');


  targetPrev.on("mouseover", function() {
    $(this).addClass('slick-prev-hover');
  });
  targetPrev.on("mouseleave", function() {
    $(this).removeClass('slick-prev-hover');
  });
  targetNext.on("mouseover", function() {
    $(this).addClass('slick-next-hover');
  });
  targetNext.on("mouseleave", function() {
    $(this).removeClass('slick-next-hover');
  });
}

// ====================================
// Slick append dots
// ====================================
appendDots();

function appendDots() {
  $( "main.main" ).append( $(".slick-dots") );
}
