import $ from 'jquery';
import Cookies from 'js-cookie';
import ga_config from './ga-config';

export default function GDPRElement(wrapper) {
  const $alert = $(wrapper);
  const $optIn = $alert.find('.opt-in-ga');
  const $optOut = $alert.find('.opt-out-ga');

  const setup = () => {
    bind();
  };

  const bind = () => {
    $optIn.one('click', optIn);
    $optOut.one('click', optOut);
  };

  const optIn = (e) => {
    if(e) e.preventDefault();
    Cookies.set('gdpr-ga', 'opt-in', {expires: 60});
    window.location.reload();
  };

  const optOut = (e) => {
    if(e) e.preventDefault();
    Cookies.set('gdpr-ga', 'opt-out', {expires: 60});
    Cookies.remove('_ga', {path: ga_config.path, domain: ga_config.domain});
    Cookies.remove('_gid', {path: ga_config.path, domain: ga_config.domain});
    Cookies.remove('_gat_gtag_' + ga_config.tracking_id.replace(/-/g, '_') /* same as the id in ga, but with _ instead of - */, {path: ga_config.path, domain: ga_config.domain});
    window.location.reload();
  };

  setup();
  
  return {}
}